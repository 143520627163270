.game-card{
    position: relative;
    display: flex;
    width: 180px;
    height: 240px;
    margin: 10px;
    padding: 9px;
    background-color: #ffffff;
    font-family: "Quicksand", sans-serif;
    font-weight: 800;
    cursor: pointer;
    clip-path: polygon(
        4% 0%,  /* Top-left cut closer to the corner */
        96% 0%,  /* Top-right cut closer to the corner */
        100% 4%, /* Right-top cut closer to the corner */
        100% 96%, /* Right-bottom cut closer to the corner */
        96% 100%, /* Bottom-right cut closer to the corner */
        4% 100%, /* Bottom-left cut closer to the corner */
        0% 96%,  /* Left-bottom cut closer to the corner */
        0% 4%   /* Left-top cut closer to the corner */
    );
}

.game-card-bg{
    width: 100%;
}

.game-card-icon{
    position: absolute;
    width: 50%;
    z-index: 10;
    transform: translate(-50%, -50%);
    top: 45%;
    left: 50%;
}

.gr-toppaper{
    position: absolute;
    width: 100%;
    z-index:-5;
    top: -300px
}

.game-container{
    height: calc(80% - 40px);
    padding-top: 10px;
    width: 80%;
}

.game-howto{
    position: absolute;
    width: 20%;
    right: 15%;
    top: 50%;
    transform: translate(0, -50%);
}

.game-page-body{
    margin: 0;
    padding: 0;
    height: 100vh;
    overflow: hidden;
    position: relative;
    font-family: Quicksand, sans-serif;
    font-weight: 800;
}


@media (max-width: 800px){
    .gr-toppaper{
        position: absolute;
        width: 100%;
        z-index:-5;
        top: 0;
    }

    .game-container{
        height: calc(80% - 40px);
        padding-top: 10px;
        width: 100%;
    }

    .game-card{
        width: 150px;
        height: auto;
    }
}

@media (max-width: 1200px){
    .game-howto{
        display: none;
    }
}