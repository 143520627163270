/******NAV-BAR*******/
.topDiv{
    height: 100px;
    background-color: white;
    top: 0;
    position: sticky;
    transition: height 0.3s ease;
    z-index: 50;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    overflow: hidden;
    margin: 0;
    padding: 0;
    box-shadow: 0 10px 10px -10px white;
}

.topDiv.shrink{
    height: 70px;
    padding-bottom: 10px;
}

.navIconContainer{
    width: 100%;
    height: 100%;
    display: flex;
    padding: 10px;
    justify-content: space-between;
}

.navIconGroup{
    margin: 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    height: 100%;
    width: 90px;
    cursor: pointer;
}

.navIcon{
    height: 50%; /* Allow the height to adjust based on the aspect ratio */
    object-fit: contain;
}

.navText{
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: smaller;
    font-weight: bold;
}

.navHomeIcon{
    flex: 1 1 auto;
    width: 100px; /* Adjust the width as needed */
    height: 80%; /* Allow the height to adjust based on the aspect ratio */
    object-fit: contain; /* Preserve the aspect ratio of the image */
}


/********SHOP*******/
.littleShopContainer{
    display: flex;
    justify-content: center;
}

.littleShop{
    position: relative;
    display: inline-block;
    padding: 20px;
}

.shopImage{
    max-width: 700px;
    width: 100%;
    height: auto;
    object-fit: contain; /* Preserve the aspect ratio of the image */
}

.shopXray{
    position: absolute;
    padding: 20px;
    width: 100%;
    object-fit: contain;
    top: 0;
    left: 0;
    z-index: 1;
}

.shopDot{
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    border: 5px solid rgba(128, 128, 128, 0.2); /* Gray border with 50% opacity */
    box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.3); /* Add the box shadow */
    transition: transform 0.2s ease-in-out;
    transform: translate(-50%, -50%);
    z-index: 5;
}

.shopDot:hover {
    transform: translate(-50%, -50%) scale(1.3);
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.8);
    border: 5px solid rgba(128, 128, 128, 0.5); /* Gray border with 50% opacity */
    box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.5); /* Add the box shadow */
}

.shopDot:hover ~ .charImage {
    transform: translateX(10px); /* Adjust the distance to move the image */
  }

.charImage {
    position: absolute;
    width: 80px;
    height: auto;
    z-index: 5;
    transition-delay: 1s;
    transition: transform 1s ease-in-out;
}

.charPopup{
    height: 80vh;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    background-color: bisque;
}

.center-child{
    display: flex;
    justify-content: center;
    align-items: center;
}

/*********BUTTONS*/
.long-btn{
    height: 60px;
    width: 200px;
    border-radius: 30px;
    background-color: #f1f1f1;
    color:#554640;
    border: 5px white solid;
    box-shadow: 1px 5px 6px 1px rgba(0, 0, 0, 0.2);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-family: "Fredoka";
    font-weight: 600;
    margin: 5px;
    cursor: pointer;
}

.tex-corrugated{
    background-image: url(../../public/assets/texture/tex_corrugated.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.circle-btn{
    height: 45px;
    width: 45px;
    background-color: #F3EBE1;
    border-radius: 50%;
    border: white 3px solid;
    box-shadow: 1px 5px 6px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.circle-btn img{
    position: absolute;
    width: 60%;
    height: auto;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    z-index: 5;
}

.title-text{
    font-weight: 600;
    font-size: 30px;
    color: #554640;
    margin-bottom: 20px;
    white-space: pre-line;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
  }

/* Hide default HTML checkbox */
.switch input {
opacity: 0;
width: 0;
height: 0;
}

/* The slider */
.slider {
position: absolute;
cursor: pointer;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: #ccc;
-webkit-transition: .4s;
transition: .4s;
}

.slider:before {
position: absolute;
content: "";
height: 80%;
width: 40%;
left: 4px;
bottom: 10%;
background-color: white;
-webkit-transition: .4s;
transition: .4s;
}

input:checked + .slider {
    background-color: #554640;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #554640;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(100%);
  }

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
    }
  
.slider.round:before {
    border-radius: 50%;
}

.switch-container{
    display: flex;
    flex-direction: column;
    width: 45px;
    height: 30px;
    margin: 10px;
}

/* React Slick */
.slick-next:before{
    color: #554640 !important;
}

.slick-prev:before{
    color: #554640 !important;
}

.happening-slider{
    padding: 0px 15%;
    z-index: 10;
    position: relative
}

.happening-box{
    height: 500px;
    display:flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.turnyourart-box{
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width: 800px){
    .happening-slider{
        padding: 0px 10%;
        z-index: 10;
        position: relative
    }

    .happening-box{
        height: 350px;
        display:flex; 
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .turnyourart-box{
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}